exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aprender-frances-js": () => import("./../../../src/pages/aprender-frances.js" /* webpackChunkName: "component---src-pages-aprender-frances-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-community-access-js": () => import("./../../../src/pages/community-access.js" /* webpackChunkName: "component---src-pages-community-access-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-comunidad-js": () => import("./../../../src/pages/comunidad.js" /* webpackChunkName: "component---src-pages-comunidad-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-pedir-acceso-js": () => import("./../../../src/pages/pedir-acceso.js" /* webpackChunkName: "component---src-pages-pedir-acceso-js" */),
  "component---src-pages-verbos-js": () => import("./../../../src/pages/verbos.js" /* webpackChunkName: "component---src-pages-verbos-js" */),
  "component---src-pages-vocabulario-js": () => import("./../../../src/pages/vocabulario.js" /* webpackChunkName: "component---src-pages-vocabulario-js" */),
  "component---src-templates-new-js": () => import("./../../../src/templates/new.js" /* webpackChunkName: "component---src-templates-new-js" */),
  "component---src-templates-verb-js": () => import("./../../../src/templates/verb.js" /* webpackChunkName: "component---src-templates-verb-js" */)
}

